import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../common/api";
import { API_ADD_PAYMENT_METHOD } from "../../../common/api/constants";


export const actionAddPaymentMethod = createAsyncThunk('appPaymentMethod/actionAddPaymentMethod', async (params, thunkAPI) => {
    try {
        return await post(API_ADD_PAYMENT_METHOD, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const appPaymentMethodSlice = createSlice({
    name: 'appPaymentMethod',
    initialState: {
        loading: false,
        addPaymentMethodResponse: null
    },
    reducers: {
        resetAddPaymentMethodResponse: (state) => {
            state.addPaymentMethodResponse = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionAddPaymentMethod.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionAddPaymentMethod.fulfilled, (state, action) => {
                state.loading = false
                state.addPaymentMethodResponse = action.payload
            }).addCase(actionAddPaymentMethod.rejected, (state, action) => {
                state.loading = false
            })
    }
})
export const { resetAddPaymentMethodResponse } = appPaymentMethodSlice.actions
export default appPaymentMethodSlice.reducer