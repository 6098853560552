/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useNavigate } from 'react-router-dom';

// ** Redux Imports
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './redux/store';
import { Toaster } from 'react-hot-toast';
import Loader from './components/loader';
import { actionGuestLogin, resetGuestLoginResponse } from './store/public/login/login';
import { getData, KEY_TOKEN, KEY_USER, setData } from './common/preferences';
import { actionUserData } from './store/common';

const LazyApp = lazy(() => import('./App'))




const WrapApp = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const dispatchRef = useRef(false);

  const { guestLoginResponse } = useSelector((state) => ({
    ...state.loginStore
  }))

  useEffect(() => {
    const checkUserAndDispatch = async () => {
      const user = getData(KEY_USER);
      if (user === null) {
        if (!dispatchRef.current) {
          dispatch(actionGuestLogin({
            email: "guest@hazelgl.com",
            password: "123456",
            login_type: "GUEST",
            social_id: 0
          }));
          dispatchRef.current = true;
        }
      }
    };

    checkUserAndDispatch();
  }, [dispatch])

  useEffect(() => {
    if (guestLoginResponse !== null) {
      dispatch(resetGuestLoginResponse())
      if (guestLoginResponse.result === true) {
        setData(KEY_USER, JSON.stringify(guestLoginResponse.response.customer))
        setData(KEY_TOKEN, guestLoginResponse.response.token)
        dispatch(actionUserData({ data: guestLoginResponse.response.customer }))
        navigate(window.location.pathname)
        // navigate('/events')
      } else {

      }
    }
  }, [guestLoginResponse])

  return (

    <LazyApp />

  );
};

export default WrapApp;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <WrapApp />
        </Suspense>
        <Toaster toastOptions={{ className: 'react-hot-toast' }} />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();