// ** Reducers Imports

import loginStore from "../store/public/login/login"
import commonStore from '../store/common'
import signupStore from '../store/public/sign_up'
import eventsStore from '../store/auth/events'
import eventDetailsStore from '../store/auth/event_details'
import eventTicketDetailsStore from '../store/auth/event_ticket_details'
import promoCodeVerification from '../store/public/promo_code_verification'
import subscriptionPlansStore from '../store/auth/subscription_plans'
import appPaymentMethodStore from '../store/auth/add_payment_method'
import appMyAccountStore from '../store/auth/my_account'
import deleteAccountStore from '../store/auth/delete_account'
import mobileNumberVerificationStore from '../store/public/mobile_number_verification';

const rootReducer = {
    loginStore,
    commonStore,
    signupStore,
    eventsStore,
    eventDetailsStore,
    eventTicketDetailsStore,
    promoCodeVerification,
    subscriptionPlansStore,
    appPaymentMethodStore,
    appMyAccountStore,
    deleteAccountStore,
    mobileNumberVerificationStore
}

export default rootReducer