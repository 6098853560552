export const KEY_USER = 'aentry_user_data';
export const KEY_TOKEN = 'aentry_token';
export const KEY_SUBSCRIPTION = 'key_subscription'

export const setData = (key, data) => {
    try {
        localStorage.setItem(key, data);
        return true
    } catch (error) {
        return false
    }
};

export const getData = (key) => {
    try {
        const value = localStorage.getItem(key);
        return (value !== null ? value : null);
    } catch (error) {
        return (null);
    }
};

export const removeData = (key) => {
    try {
        localStorage.removeItem(key);
        return (true);
    } catch (error) {
        return (null);
    }
};

export const clearSharedPref = () => {
    try {
        localStorage.clear();
        return (true);
    } catch (error) {
        return (null);
    }
};





