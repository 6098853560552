import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post } from "../../../common/api";
import { API_SAVE_MOBILE_NUMBER, API_COUNTRY } from "../../../common/api/constants";


export const actionSaveMobileNumber = createAsyncThunk('appMobileVerification/actionSaveMobileNumber', async (params, thunkAPI) => {
    try {
        return await post(API_SAVE_MOBILE_NUMBER, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})


export const actionGetDefaultCountry = createAsyncThunk('appMobileVerification/actionGetDefaultCountry', async (thunkAPI) => {
    try {
        return await get(API_COUNTRY, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const appSaveMobileNumberSlice = createSlice({
    name: 'appMobileVerification',
    initialState: {
        loading: false,
        saveMobileNumberResponse: null,

        countryResponse: null

    },
    reducers: {
        resetSaveMobileNumberResponse: (state) => {
            state.saveMobileNumberResponse = null
        },
        resetCountryResponse: (state) => {
            state.countryResponse = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionSaveMobileNumber.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionSaveMobileNumber.fulfilled, (state, action) => {
                state.loading = false
                state.saveMobileNumberResponse = action.payload
            }).addCase(actionSaveMobileNumber.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(actionGetDefaultCountry.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionGetDefaultCountry.fulfilled, (state, action) => {
                state.loading = false
                state.countryResponse = action.payload
            }).addCase(actionGetDefaultCountry.rejected, (state, action) => {
                state.loading = false
            })
    }
})
export const { resetSaveMobileNumberResponse, resetCountryResponse } = appSaveMobileNumberSlice.actions
export default appSaveMobileNumberSlice.reducer