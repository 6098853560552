import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post } from "../../../common/api";
import {
    API_CUSTOMER_DETAILS,
    API_CUSTOMER_SUBSCRIPTION_DETAILS,
    API_CANCEL_CUSTOMER_SUBSCRIPTION,
    API_PAYMENT_METHODS
} from "../../../common/api/constants";


export const actionCustomerDetails = createAsyncThunk('appMyAccount/actionCustomerDetails', async (thunkAPI) => {
    try {
        return await get(API_CUSTOMER_DETAILS, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionSubscriptionDetails = createAsyncThunk('appMyAccount/actionSubscriptionDetails', async (thunkAPI) => {
    try {
        return await get(API_CUSTOMER_SUBSCRIPTION_DETAILS, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionCancelCustomerSubscription = createAsyncThunk('appMyAccount/actionCancelCustomerSubscription', async (params, thunkAPI) => {
    try {
        return await post(API_CANCEL_CUSTOMER_SUBSCRIPTION, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionPaymentMethods = createAsyncThunk('appMyAccount/actionPaymentMethods', async (thunkAPI) => {
    try {
        return await get(API_PAYMENT_METHODS, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})




export const appMyAccountSlice = createSlice({
    name: 'appMyAccount',
    initialState: {
        loading: false,
        customerDetailsResponse: null,

        subscriptionDetailsResponse: null,

        cancelSubscriptionResponse: null,

        paymentMethodResponse: null
    },
    reducers: {
        resetCustomerDetailsResponse: (state) => {
            state.customerDetailsResponse = null
        },
        resetSubscriptionDetailsResponse: (state) => {
            state.subscriptionDetailsResponse = null
        },
        resetCancelSubscriptionResponse: (state) => {
            state.cancelSubscriptionResponse = null
        },
        resetPaymentMethodResponse: (state) => {
            state.paymentMethodResponse = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionCustomerDetails.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionCustomerDetails.fulfilled, (state, action) => {
                state.loading = false
                state.customerDetailsResponse = action.payload
            }).addCase(actionCustomerDetails.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(actionSubscriptionDetails.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionSubscriptionDetails.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptionDetailsResponse = action.payload
            }).addCase(actionSubscriptionDetails.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(actionCancelCustomerSubscription.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionCancelCustomerSubscription.fulfilled, (state, action) => {
                state.loading = false
                state.cancelSubscriptionResponse = action.payload
            }).addCase(actionCancelCustomerSubscription.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(actionPaymentMethods.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionPaymentMethods.fulfilled, (state, action) => {
                state.loading = false
                state.paymentMethodResponse = action.payload
            }).addCase(actionPaymentMethods.rejected, (state, action) => {
                state.loading = false
            })
    }
})
export const {
    resetCustomerDetailsResponse,
    resetSubscriptionDetailsResponse,
    resetCancelSubscriptionResponse,
    resetPaymentMethodResponse
} = appMyAccountSlice.actions
export default appMyAccountSlice.reducer