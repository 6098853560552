import { ENV } from "../api/constants"

export const API_TIMEOUT = 5000

/**
 * API Error Codes
 */
export const UNAUTHORIZED = 400
export const FORBIDDEN = 403
export const SUCCESS = 200
export const ERROR = 410

export const TOAST_DURATION = 4000
export const TOAST_POSITION = 'top-center'
export const TOAST_SUCCESS_COLOR = 'green'
export const TOAST_ERROR_COLOR = 'red'

export const STRIPE_PUBLISH_KEY = ENV === 'stage' ?
    "pk_test_51LNX0GAbc3GqWAOr3wWu5G4I7z6wcpELkh4dUSWoRaPvJfH6TjeUuNkegKSpBrIFXB9fA9qdhX4BdyiosNVQWktg00StKV0Vo5" :
    "pk_live_51LNX0GAbc3GqWAOrtyVuJz4zoI7WSsJH3ZE71D9IYaTsaOhNjXdvveB40bPGycBxpjkIyy6HEWkCxAEAUxZ8zfvP00qfBVKQeb"