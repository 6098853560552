import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../common/api";
import { API_USER_LOGIN, API_VERIFY_LOGIN } from "../../../common/api/constants";


export const actionUserLogin = createAsyncThunk('appLogin/actionUserLogin', async (params, thunkAPI) => {
    try {
        return await post(API_USER_LOGIN, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionGuestLogin = createAsyncThunk('appLogin/actionGuestLogin', async (params, thunkAPI) => {
    try {
        return await post(API_VERIFY_LOGIN, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionVerifyLogin = createAsyncThunk('appLogin/actionVerifyLogin', async (params, thunkAPI) => {
    try {
        return await post(API_VERIFY_LOGIN, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const appLoginSlice = createSlice({
    name: 'appLogin',
    initialState: {
        loading: false,
        userLoginResponse: null,

        guestLoginLoading: false,
        guestLoginResponse: null,
        verifyLoginLoading: false,
        verifyLoginResponse: false
    },
    reducers: {
        resetUserLoginResponse: (state) => {
            state.userLoginResponse = null
        },
        resetGuestLoginResponse: (state) => {
            state.guestLoginResponse = null
        },
        resetVerifyLoginResponse: (state) => {
            state.verifyLoginResponse = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionUserLogin.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionUserLogin.fulfilled, (state, action) => {
                state.loading = false
                state.userLoginResponse = action.payload
            }).addCase(actionUserLogin.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(actionGuestLogin.pending, (state, action) => {
                state.guestLoginLoading = true
            })
            .addCase(actionGuestLogin.fulfilled, (state, action) => {
                state.guestLoginLoading = false
                state.guestLoginResponse = action.payload
            }).addCase(actionGuestLogin.rejected, (state, action) => {
                state.guestLoginLoading = false
            })

            .addCase(actionVerifyLogin.pending, (state, action) => {
                state.verifyLoginLoading = true
            })
            .addCase(actionVerifyLogin.fulfilled, (state, action) => {
                state.verifyLoginLoading = false
                state.verifyLoginResponse = action.payload
            }).addCase(actionVerifyLogin.rejected, (state, action) => {
                state.verifyLoginLoading = false
            })
    }
})
export const {
    resetUserLoginResponse,
    resetGuestLoginResponse,
    resetVerifyLoginResponse
} = appLoginSlice.actions
export default appLoginSlice.reducer