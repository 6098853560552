import axios from 'axios'
import { KEY_TOKEN, getData } from '../preferences'
import { API_TIMEOUT } from '../constants'

export const getMyConfig = async (objConfig) => {
    var config = {}

    config.timeout = API_TIMEOUT
    config.headers = {}

    if (objConfig !== null && objConfig?.isOpenApi === false) {
        var token = getData(KEY_TOKEN)
        if (token != null) {
            config.headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                authorization: `Token ${token}`,
                platform: 'web',
                buildno: "1.0",
                devicetoken: null,
            };
        } else {
            config.headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                platform: 'web',
                buildno: "1.0",
                devicetoken: null,
            };
        }
    }

    if (objConfig !== null && objConfig.isMultipart === true) {
        config.headers["Content-Type"] = "multipart/form-data";
        config.headers["accept"] = "*/*";
        config.headers.platform = 'web';
        config.headers.buildno = "1.0";
        config.headers.devicetoken = null;
    }


    return config
}

export const post = async (url, params, thunkAPI, isOpenApi = false) => {
    try {
        const objConfig = await getMyConfig({
            isOpenApi,
            isMultipart: false
        });
        // console.log('API:', url);
        // console.log('PARAMS:', params);
        // console.log('objConfig:', objConfig);
        const response = await axios.post(url, params, objConfig)
        // console.log('RESPONSE:', JSON.stringify(response.data));
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ custom_error: error.message })
    }
}

export const multipart = async (url, params, thunkAPI, isOpenApi = false) => {
    try {
        const objConfig = await getMyConfig({
            isOpenApi,
            isMultipart: true
        });

        const response = await axios.post(url, params, objConfig)
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ custom_error: error.message })
    }
}

export const put = async (url, params, thunkAPI, isOpenApi = false) => {
    try {
        const response = await axios.put(url, params, getMyConfig({ isOpenApi }))
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ custom_error: error.message })
    }
}

export const get = async (url, thunkAPI, isOpenApi = false) => {
    try {
        const objConfig = await getMyConfig({
            isOpenApi
        });
        // console.log('API:', url);
        const response = await axios.get(url, objConfig)
        // console.log('RESPONSE:', JSON.stringify());
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ custom_error: error.message })
    }
}