import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../common/api";
import { API_EVENT_TICKET_DETAILS, API_TICKET_VOUCHER_CODE_VERIFY, API_TICKET_PURCHASE } from "../../../common/api/constants";


export const actionEventTicketDetails = createAsyncThunk('appEventTicketDetails/actionEventTicketDetails', async (params, thunkAPI) => {
    try {
        return await post(API_EVENT_TICKET_DETAILS, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionEventTicketVoucherCodeVerify = createAsyncThunk('appEventTicketDetails/actionEventTicketVoucherCodeVerify', async (params, thunkAPI) => {
    try {
        return await post(API_TICKET_VOUCHER_CODE_VERIFY, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionTicketPurchase = createAsyncThunk('appEventTicketDetails/actionTicketPurchase', async (params, thunkAPI) => {
    try {
        return await post(API_TICKET_PURCHASE, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const appEventTicketDetailsSlice = createSlice({
    name: 'appEventTicketDetails',
    initialState: {
        loading: false,
        eventTicketDetailsResponse: null,

        ticketVoucherCodeVerifyLoading: false,
        ticketVoucherCodeVerifyResponse: null,

        loadingTicketPurchase: false,
        ticketPurchaseResponse: null
    },
    reducers: {
        resetEventTicketDetailsResponse: (state) => {
            state.eventTicketDetailsResponse = null
        },
        resetTicketVoucherCodeVerifyResponse: (state) => {
            state.ticketVoucherCodeVerifyResponse = null
        },
        resetTicketPurchaseResponse: (state) => {
            state.ticketPurchaseResponse = null
        }

    },
    extraReducers: builder => {
        builder
            .addCase(actionEventTicketDetails.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionEventTicketDetails.fulfilled, (state, action) => {
                state.loading = false
                state.eventTicketDetailsResponse = action.payload
            }).addCase(actionEventTicketDetails.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(actionEventTicketVoucherCodeVerify.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionEventTicketVoucherCodeVerify.fulfilled, (state, action) => {
                state.loading = false
                state.ticketVoucherCodeVerifyResponse = action.payload
            }).addCase(actionEventTicketVoucherCodeVerify.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(actionTicketPurchase.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionTicketPurchase.fulfilled, (state, action) => {
                state.loading = false
                state.ticketPurchaseResponse = action.payload
            }).addCase(actionTicketPurchase.rejected, (state, action) => {
                state.loading = false
            })
    }
})
export const {
    resetEventTicketDetailsResponse,
    resetTicketVoucherCodeVerifyResponse,
    resetTicketPurchaseResponse
} = appEventTicketDetailsSlice.actions
export default appEventTicketDetailsSlice.reducer