import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { KEY_TOKEN, KEY_USER, getData } from "../../common/preferences";

export const actionUserData = createAsyncThunk('appCommon/actionUserData', (params, thunkAPI) => {
    try {

        if (params && params?.data) {
            return {
                userObj: params?.data
            };
        }

        var token = getData(KEY_TOKEN);
        var userData = getData(KEY_USER);

        if (token != null) {
            let userDataObj = {
                userObj: JSON.parse(userData)
            };
            return userDataObj;
        } else {
            let userDataObj = {
                userObj: null
            };
            return userDataObj;
        }
    } catch (error) {
        throw error;
    }
}
);

export const actionSocialUserData = createAsyncThunk('appCommon/actionSocialUserData', (params, thunkAPI) => {
    return params
}
);

export const appCommonSlice = createSlice({
    name: 'appCommon',
    initialState: {
        userDataResponse: null,
        socialUserData: null
    },
    reducers: {
        resetUserDataResponse: (state) => {
            state.userDataResponse = null;
        },
        resetSocialUserDataResponse: (state) => {
            state.socialUserData = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionUserData.fulfilled, (state, action) => {
                state.userDataResponse = action.payload;
            })

            .addCase(actionSocialUserData.fulfilled, (state, action) => {
                state.socialUserData = action.payload;
            })
    }
});

export const { resetUserDataResponse, resetSocialUserDataResponse } = appCommonSlice.actions;
export default appCommonSlice.reducer;
