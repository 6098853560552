import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../../common/api";
import { API_EVENTS } from "../../../common/api/constants";


export const actionEvents = createAsyncThunk('appEvents/actionEvents', async (thunkAPI) => {
    try {
        return await get(API_EVENTS, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const appEventsSlice = createSlice({
    name: 'appEvents',
    initialState: {
        loading: false,
        eventsResponse: null
    },
    reducers: {
        resetEventsResponse: (state) => {
            state.eventsResponse = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionEvents.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionEvents.fulfilled, (state, action) => {
                state.loading = false
                state.eventsResponse = action.payload
            }).addCase(actionEvents.rejected, (state, action) => {
                state.loading = false
            })
    }
})
export const { resetEventsResponse } = appEventsSlice.actions
export default appEventsSlice.reducer