import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../common/api";
import { API_EVENT_DETAILS } from "../../../common/api/constants";


export const actionEventDetails = createAsyncThunk('appEventDetails/actionEventDetails', async (params, thunkAPI) => {
    try {
        return await post(API_EVENT_DETAILS, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const appEventDetailsSlice = createSlice({
    name: 'appEventDetails',
    initialState: {
        loading: false,
        eventDetailsResponse: null
    },
    reducers: {
        resetEventDetailsResponse: (state) => {
            state.eventDetailsResponse = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionEventDetails.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionEventDetails.fulfilled, (state, action) => {
                state.loading = false
                state.eventDetailsResponse = action.payload
            }).addCase(actionEventDetails.rejected, (state, action) => {
                state.loading = false
            })
    }
})
export const { resetEventDetailsResponse } = appEventDetailsSlice.actions
export default appEventDetailsSlice.reducer