import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../common/api";
import { API_VERIFY_PROMO_CODE } from "../../../common/api/constants";


export const actionVerifyPromoCode = createAsyncThunk('appPromoCodeVerification/actionVerifyPromoCode', async (params, thunkAPI) => {
    try {
        return await post(API_VERIFY_PROMO_CODE, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const appPromoCodeVerificationSlice = createSlice({
    name: 'appPromoCodeVerification',
    initialState: {
        loading: false,
        promoCodeVerificationResponse: null
    },
    reducers: {
        resetPromoCodeResponse: (state) => {
            state.promoCodeVerificationResponse = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionVerifyPromoCode.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionVerifyPromoCode.fulfilled, (state, action) => {
                state.loading = false
                state.promoCodeVerificationResponse = action.payload
            }).addCase(actionVerifyPromoCode.rejected, (state, action) => {
                state.loading = false
            })
    }
})
export const { resetPromoCodeResponse } = appPromoCodeVerificationSlice.actions
export default appPromoCodeVerificationSlice.reducer